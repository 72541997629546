import React, { Fragment, useEffect, useState} from "react";
import { Modal, ModalHeader, Button, ModalFooter} from "reactstrap";
import useAxios from "../utils/useAxios";


const UploadsRemovalModal = (props) => {
    const [modal, setModal] = useState(false)
    const api = useAxios();

    useEffect( () => {
        props.resetState();
    }, [])

    const toggle = () => {
        setModal(!modal)
    };

    const deleteUploads = async (id) => {
        try {
            const response = await api.delete('/upload/'+ id + '/delete')
            props.resetState();
            toggle();
        } catch (err) {
            console.log('Something went wrong');
            console.log(err);
            toggle();
        }
    };

    return (
        <Fragment>
        <Button color="danger" onClick={() => toggle()}>
          Usuń
        </Button>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>
            Czy na pewno chesz usunąć ten plik?
          </ModalHeader>

          <ModalFooter>
            <Button type="button" onClick={() => toggle()}>
              Cancel
            </Button>
            <Button
              type="button"
              color="secondary"
              onClick={() => deleteUploads(props.id)}
            >
              Yes
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
}


export default UploadsRemovalModal;
