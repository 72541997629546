import React from "react";
import { Table} from "reactstrap";
import UploadsRemovalModal from "./UploadsRemovalModal";
import UploadSendModal from "./UploadSednModal";
import Moment from 'react-moment';

const RCSUploadsList = (props) => {
        return (
            <Table dark>
                <thead>
                <tr>
                    <th>Załadowany Plik</th>
                    <th>Data załadowania</th>
                    <th>Status</th>
                    <th>Dostępne numery</th>
                    <th></th>
                </tr>
                </thead>

                <tbody>
                {!props.uploads || props.uploads.length <= 0 || !Array.isArray(props.uploads) ? (
                <tr>
                    <td colSpan="6" align="center">
                        <b> Nie załadowano jeszcze żadnego pliku. </b>
                    </td>
                </tr>
                ) : (
                    props.uploads.map(upload => (
                        // console.log(String(`${process.env.REACT_APP_API_URL}/rcs_api/rcstemplates/${id}/`));
                        <tr key={upload.id}>
                            <td><a href={`${process.env.REACT_APP_API_URL}/rcs_api/download/${upload.upload_id}/`} download>{upload.upload?.replace(/^.*[\\\/]/, '')}</a></td>
                            <td align="center"><Moment format="YYYY/MM/DD hh:mm:ss">{upload?.created_at}</Moment></td>
                            <td align="center">{upload?.status} {upload?.status === 'WERYFIKACJA' ? String(Math.floor((upload.verified_numbers/upload.all_numbers) * 100)) + "%" : "" }</td>
                            <td align="center">{upload?.valid_numbers}/{upload?.all_numbers}</td>
                            <td align="center">
                                <UploadsRemovalModal
                                    id={upload.id}
                                    resetState={props.resetState}
                                />
                                <UploadSendModal
                                    upload={upload}
                                    templates={props.templates}
                                    resetState={props.resetState}
                                />
                            </td>
                        </tr>
                    ))
                )}
                </tbody>
            </Table>
        )
    }

export  default RCSUploadsList;