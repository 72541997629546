import AuthContext from "./AuthContext";
import { Navigate } from "react-router-dom";
import {useContext} from "react";


export const ProtectedRoute = ({ children }) => {
   const {user} = useContext(AuthContext);
   if (!user) {
      // user is not authenticated
      return <Navigate to="/login"/>;
   }
   return children;
};