import React, { useState, useEffect } from "react";
import {Col, Container, Row} from "reactstrap";
import Navbar from "../components/Navbar";

import useAxios from "../utils/useAxios";
import MessagesList from "../components/MessagesList";

const MessagesPage = () => {
    const [messages, setMessages] = useState([]);
    const api = useAxios();

    useEffect(() => {
        resetState();
    }, [])

    const getMessages = async () => {
        try {
            const response = await api.get("/messages/");
            setMessages(response.data);
        } catch {
            console.log("Something went wrong");
            setMessages([]);
        }
    }

    const resetState = () => {
        getMessages();
    };

    return (
        <div>
         <Navbar/>
        <Container style={{ marginTop: "20px"}}>
            <Row style={{ marginTop: "20px"}}>
                <Col>
                    <MessagesList
                        messages={messages}
                        resetState={resetState}
                    />
                </Col>
            </Row>
        </Container>
        </div>

    );
}

export default MessagesPage;
