import React, { useState, useEffect } from "react";
import {Col, Container, Row} from "reactstrap";
import NewRCSTemplateModal from "../components/NewRCSTemplateModal";
import useAxios from "../utils/useAxios";
import RCSUploadsList from "../components/UploadsList";
import NewRCSUploadForm from "../components/NewUploadsForm";
import Navbar from "../components/Navbar";

const UploadsPage = () => {
    const [uploads, setUploads] = useState([]);
    const [rcstemplates, setRCSTemplate] = useState([]);
    const api = useAxios();

    useEffect(() => {
        resetState();
    }, [])

    const getUploads = async () => {
        try {
            const response = await api.get("/uploadedContacts/");
            setUploads(response.data);
        } catch {
            setUploads([]);
        }
    };


    const getRCSTemplates = async () => {
        try {
            const response = await api.get("/rcstemplates/");
            setRCSTemplate(response.data);
        } catch {
            console.log("Something went wrong");
            setRCSTemplate([]);
        }
    };


    const resetState = () => {
        getUploads();
        getRCSTemplates();
    };

    return (
        <div>
            <Container style={{ marginTop: "20px"}}>
            <Row>
                <Col>
                    <NewRCSUploadForm resetState={resetState} />
                </Col>
            </Row>
            <Row style={{ marginTop: "20px"}}>
                <Col>
                    <RCSUploadsList
                        uploads={uploads}
                        resetState={resetState}
                        templates={rcstemplates}
                    />
                </Col>
            </Row>
        </Container>
        </div>
    );

}

export default UploadsPage;