
import React, {useContext, useEffect, useState} from "react";
import {Button, Form, FormGroup, Input, Label} from "reactstrap";
import AuthContext from "../context/AuthContext";


function SendRCSTemplateForm(props) {
    const [id, setId] = useState(0);
    const [name, setName] = useState("");
    const [template, setTemplate] = useState("");
    const [msisdn, setMsisdn] = useState("");
    const { authTokens } = useContext(AuthContext);
    const [error, setError] = useState("");

    // on load setstate
    useEffect(() => {
        if (props.rcstemplate) {
            const {id, name, template} = props.rcstemplate;
            setId(id);
            setName(name);
            setTemplate(template);
        }
    }, []);

    const sendRCSTemplate = async (e) =>  {
        e.preventDefault();
        const formData = new FormData();
        const msisdnPattern = new RegExp('^\\+48\\d{9}$');


        formData.append('template_id', id);
        formData.append('recipient', msisdn);
        if (!msisdnPattern.test(msisdn)){
            setError("Numer telefonu powinien być w formacie +48XXXXXXXXX");
        } else {
            setError('');
            const response = await fetch(`${process.env.REACT_APP_API_URL}/rcs_api/rcstemplate/send`, {
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + authTokens.access
            },
            body: formData
        });
        if (response.ok){
            props.toggle();
            alert("Wiadomość została wysłana.");
        } else {
            setError('Nie udało się wysłać wiadomości. Staus błędu: ' + response.status);
        }
        }

    };


    const onMsisdnChange = (event) => {
        const msisdn = event.target.value;
        setMsisdn(msisdn);
    };

    return (
        <Form onSubmit={sendRCSTemplate}>
            <FormGroup>
                <div>
                    <p id ='errors' style={{color: "red"}}>{error}</p>
                    <Label for="msisdn">Numer telefonu:</Label>
                    <Input
                        type="text"
                        name="msisdn"
                        onChange={onMsisdnChange}
                        value={msisdn}
                    />
                    <p id ='msisdn_info'>{"Numer telefonu powinien być w formacie +48XXXXXXXXX"}</p>
                </div>

            </FormGroup>
            <Button>Wyślij</Button>
        </Form>
    );
}

export default SendRCSTemplateForm;
