import DemoForm from "../components/DemoForm/DemoFormComponent";
import "../components/DemoForm/DemoFormStyle.scss";

const DemoPage = () => {
     return (
      <div className="demo-page"
           style={{
             display: 'flex',
             alignItems: 'center',
             justifyContent: 'center',
             height: '100vh',
             maxHeight: '1000px',
           }}
    >
         <DemoForm/>
      </div>
     )
}

export default DemoPage