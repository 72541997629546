import {Form, Select, Input, Button, Checkbox} from 'antd';
import { ReactComponent as MprofiLogo } from '../../assets/logo_mprofi_pl_2.svg';

import './DemoFormStyle.scss';
import {useEffect, useState} from "react";

const SITE_KEY = "6Lc1dwcoAAAAADrmPKJ5tOGTx0M-A92_aZnPk3K0";

const DemoForm = (props) => {
    const [checked, setChecked] = useState(false);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);


    const handleLoaded = _ => {
      window.grecaptcha.ready(_ => {
        window.grecaptcha
          .execute(SITE_KEY, { action: "homepage" })
          .then(token => {
            console.log('...');
          })
      })
    }

    useEffect(() => {
      // Add reCaptcha
      const script = document.createElement("script");
      script.src = `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`;
      script.addEventListener("load", handleLoaded);
      document.body.appendChild(script);
    }, []);


    const formItemLayout = {
        labelCol: { xs: { span: 20, offset:1 }, sm: { span: 23, offset:1  }, md: { span: 23, offset:1  }},
        wrapperCol: { xs: { span: 20, offset:1 }, sm: { span: 23, offset:1  }, md: { span: 23, offset:1  }},
        labelAlign: "right"
    };

    const send_form = async(values) =>{
         const response = await fetch(`${process.env.REACT_APP_API_URL}/rcs_api/msisdn_verify`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json;charset=UTF-8',},
            body: JSON.stringify({
                "msisdn": '+48'+values.msisdn,
                "company_name": values.company_name,
                "g-recaptcha-response": values.token,
            })
        })
            .then(response => response.json())
            .then((data) => {
                    if (data.provider === 'ORANGE' || data.provider==='T-MOBILE'){
                        alert('Wiadomość wkrótce do Ciebie dotrze.');
                    } else {
                        alert('Niestety twój operator lub telefon nie obsługuje wiadomości RCS');
                    }
                    setLoading(false);
        }).catch((error) => {
                  console.log('EROR:', error);
                });
    };


    const onFinish = async(values) => {
        setLoading(true);
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(SITE_KEY, {action: 'submit'}).then(token => {
                send_form({'msisdn': values.msisdn, 'token': token, 'company_name': values.company_name});
            });
        });
    };

    const onChange = (e) => {
        setChecked(e.target.checked);
    };


  return (
      <div className="demo-form">
          <div className="demo-form-header">
                  <h1 className='header-text'>RCS DEMO</h1>
          </div>
            <Form
                form={form}
                name="control-hooks"
                onFinish={onFinish}
                layout='vertical'
                class='demo-form'
            >
            <p className="demo-form-label"> Numer telefonu</p>
            <Form.Item
                {...formItemLayout}
                name="msisdn"
                rules={[
                    {
                        required: true,
                        message: 'Numer telefonu jest wymagany!'
                    },
                ]}
            >
                <Input style={{width: '90%'}} addonBefore="+48"/>
            </Form.Item>


            <p className="demo-form-label"> Nazwa firmy (opcjonalne) </p>
            <Form.Item
                {...formItemLayout}
                name="company_name"
                rules={[
                    {
                        required: false,
                    },
                ]}
            >
                <Input style={{width: '90%'}}/>
            </Form.Item>

            <Form.Item
                {...formItemLayout}
                  name="terms_and_condition"
                  valuePropName="checked"
                  rules={[
                      {
                        validator: (_, value) =>
                          value ? Promise.resolve() : Promise.reject(new Error('Bez Twojej zgody nie wyślemy Ci żadnej wiadomości.')),
                      },
                ]}
                >
                <Checkbox onChange={onChange}>Akceptuję <a href={"https://mprofi.pl/regulamin-rodo/"}>warunki usługi </a> i <a href={"https://mprofi.pl/polityka-prywatnosci-rodo/"}>przetwarzania danych</a></Checkbox>
            </Form.Item>

            <Form.Item
                style={{
                     display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'center',
                }}>
                <Button
                    id="submit-button"
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    disabled={
                        form.getFieldsError().filter(({ errors }) => errors.length).length > 0
                    }
                    style={{
                        backgroundColor: "var(--main-theme-color)",
                        color: "white"}}
                >
                    Wyślij wiadomość testową
                </Button>
            </Form.Item>

            </Form>
          <div className="demo-form-footer">
            <MprofiLogo className='logo'/>
          </div>
      </div>
  )

}

export default DemoForm