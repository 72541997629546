import React, { useState, useEffect } from "react";
import {Col, Container, Row} from "reactstrap";
import useAxios from "../utils/useAxios";
import RCSTemplateStatisticsList from "../components/RCSTemplateStatisticsList";
import Navbar from "../components/Navbar";

const ReportsPage = () => {
    const [statistics, setStatistics] = useState([]);
    const api = useAxios();

    useEffect(() => {
        resetState();
    }, [])

    const getStatistics = async () => {
        try {
            const response = await api.get("/statisticsreports/");
            setStatistics(response.data);
        } catch {
            console.log("Something went wrong");
            setStatistics([]);
        }
    };

    const resetState = () => {
        getStatistics();
    };

    return (
        <div>
            <Navbar/>
            <Container style={{ marginTop: "20px"}}>
            <Row style={{ marginTop: "20px"}}>
                <Col>
                    <RCSTemplateStatisticsList
                        statistics={statistics}
                        resetState={resetState}
                    />
                </Col>
            </Row>
        </Container>
        </div>

    );
}

export default ReportsPage;
