import React from "react";
import {useEffect, useState, useContext} from 'react';
import { Button, Form, Input, Select, Radio, Card, Image, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import Home from "../views/homePage";
import AuthContext from "../context/AuthContext";

import './Home.style.scss'
import Navbar from "../components/Navbar";


const { Option } = Select;
const { Meta } = Card;
const { TextArea } = Input

const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };


const HomePage = () => {
    const formRef = React.useRef(null)
    const {authTokens} = useContext(AuthContext);
    const [orientation, setOrientation] = useState('VERTICAL')
    const [size, setSize] = useState('MEDIUM')
    const [position, setPosition] = useState('LEFT')


    const card_style = {
      "VERTICAL": {
        "SMALL" : {
          "width": '100px',
          "height": '20px',
        },
        "MEDIUM" : {
          "width": '100px',
          "height": '40px',
        },
        "LARGE" : {
          "width": '100px',
          "height": '60px',
        },
      },
      "HORIZONTAL": {
        "SMALL" : {
          "width": '200px',
          "height": '100px',
        },
        "MEDIUM" : {
          "width": '150px',
          "height": '75px',
        },
        "LARGE" : {
          "width": '100px',
          "height": '25px',
        }, 
      }
    };


    const normFile = (e) => {
      if (Array.isArray(e)) {
        return e;
      }
      return e?.fileList;
    };

    const onOrientationChange = (value) => {
        setOrientation(value.target.value)
    };

    const onSizeChange = (value) => {
      setSize(value.target.value)
      
  };

    const onFinish = (values) => {
        const formData = new FormData();

        console.log('VALUE', values)
		    formData.append('media_file', values.thumbnail[0].originFileObj);

        formData.append('card_layout', values.layout);
        formData.append('media_height', values.size);
        formData.append('img_alignment', values.position);
        
        formData.append('title', values.title);
        formData.append('description', values.description);

        const response = fetch(`${process.env.REACT_APP_API_URL}/rcs_api/send`, {
          method: "POST",
          headers: {
              Authorization: 'Bearer ' + authTokens.access
          },
          body: formData,
      });
    };

    const onReset = () => {
        console.log('On reset', formRef.current)
        formRef.current?.resetFields();
    };


    const onFill = () => {
        formRef.current?.setFieldsValue({
          msisdn: '+48692498898',
          orientation: 'vertical',
        });
      };


    useEffect(() => {
        console.log('USE EFFECT', orientation)
    }, [orientation])
  
    useEffect(() => {
      console.log('FormRef', orientation)
    }, [Form])


    return(
      <div>
          <Navbar/>
        <div className='send-form'>
            <Form
                {...layout}
                ref={formRef}
                name="send-form"
                onFinish={onFinish}
                style={{
                    maxWidth: 600,
                }}
            >

            <Form.Item label="Układ" name="layout" onChange={onOrientationChange}>
            <Radio.Group value={orientation}>
              <Radio.Button value="HORIZONTAL">Poziomy</Radio.Button>
              <Radio.Button value="VERTICAL">Pionowy</Radio.Button>
            </Radio.Group>
            </Form.Item>
            
            <Form.Item label="Rozmiar" name="size" onChange={onSizeChange}>
            <Radio.Group value={size}>
              <Radio.Button value="SMALL">Mały</Radio.Button>
              <Radio.Button value="MEDIUM">Średni</Radio.Button>
              <Radio.Button value="LARGE">Duży</Radio.Button>
            </Radio.Group>
            </Form.Item>

            <Form.Item label="Pozycja" name="position">
            <Radio.Group value={size}>
              <Radio.Button value="LEFT">Lewa</Radio.Button>
              <Radio.Button value="RIGHT">Prawa</Radio.Button>
            </Radio.Group>
            </Form.Item>

            <Form.Item name="thumbnail" label="media" valuePropName="fileList" getValueFromEvent={normFile}>
            <Upload listType="picture-card" accept="image/png, image/jpeg" maxCount={1}>
              <div>
                <PlusOutlined />
                <div
                  style={{
                    marginTop: 8,
                  }}
                >
                  Upload
                </div>
              </div>
            </Upload>
            </Form.Item>

          <Form.Item label="Tytuł" name='title'>
            <Input placeholder="input placeholder" />
          </Form.Item>
          
          <Form.Item label="Opis" name='description'>
                <TextArea
                placeholder="Controlled autosize"
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
          </Form.Item>   

            <Form.Item {...tailLayout}>
                <Button type='primary' htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    )
}

export default HomePage;