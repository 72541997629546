import React, { useState, useEffect } from "react";
import {Col, Container, Row} from "reactstrap";
import useAxios from "../utils/useAxios";
import RCSTemplateStatisticsList from "../components/RCSTemplateStatisticsList";
import BotCard from "../components/BotCard";
import {Space} from "antd";
import BotEditForm from "../components/BotEditForm";
import Navbar from "../components/Navbar";


const BotPage = () => {
    const [bot, setBot] = useState(null);
    const api = useAxios();

    useEffect(() => {
        resetState();
    }, [])

    const getBot = async () => {
        try {
            const response = await api.get("/bots/2/");
            setBot(response.data);
        } catch {
            setBot(null);
        }
    };

    const resetState = () => {
        getBot();
    };

    return (
        <div>
            <Navbar/>
        <Row style={{ marginTop: '1ex' }}>
              <Col span={2} align={"center"}></Col>
              <Col span={16}>
                    <Space direction="vertical" size={16} align="center">
                    <BotCard
                        bot={bot}
                        resetState={resetState}
                    />
                    </Space>
              </Col>
              <Col span={2}></Col>
        </Row>
        <Row style={{ marginTop: '1ex' }}>
              <Col span={2} align={"center"}></Col>
              <Col span={16}>
                    <BotEditForm
                        bot={bot}
                    />
              </Col>
              <Col span={2}></Col>
        </Row>
        </div>
    );
}

export default BotPage;