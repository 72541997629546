import React, {useContext, useEffect, useState} from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import AuthContext from "../context/AuthContext";
import useAxios from "../utils/useAxios";
import {Image} from "antd";

function Navbarrrr () {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const [bot, setBot] = useState(null);
  const api = useAxios();

      useEffect(() => {
          resetState();
      }, [])

      const getBot = async () => {
          try {
              const response = await api.get("/bots/2/");
              setBot(response.data);
          } catch {
              setBot(null);
          }
      };

      const resetState = () => {
          getBot();
      };

    function toggle() {
      console.log('Do sth on click');
    }

    return (
      <div>
        <Navbar color="light" light expand="md">
          <NavbarBrand href="/">RCS</NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink href="/templates/">Szablony</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/uploads/">Wysyłka z pliku</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/messages/">Wiadomości</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/history/">Historia wysyłek</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/reports/">Raporty</NavLink>
              </NavItem>
              {!user ? (
                    <NavItem><NavLink href="/login/">Zaloguj się</NavLink></NavItem>
                ) : (
                <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  <img src={bot?.logo} height={30}/>
                </DropdownToggle>
                <DropdownMenu end>
                  <DropdownItem>
                    <NavItem><NavLink href="/bot">Informacje o bocie</NavLink></NavItem>
                  </DropdownItem>
                  <DropdownItem>
                    <NavItem><NavLink href="/logout">Wyloguj się</NavLink></NavItem>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              )
              }
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
}

export default  Navbarrrr