import { PlusOutlined, EditOutlined, GlobalOutlined, PhoneOutlined , MailOutlined, ReadOutlined } from '@ant-design/icons';
import {ColorPicker, Card, Form, Input, Button, Upload} from 'antd';
import {useEffect, useState} from 'react';
const { Meta } = Card;


const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};


const BotEditForm = (props) => {
  const {bot } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
        bot_name: bot?.bot_name,
        description: bot?.description,
        bot_color: bot?.bot_color,
        msisdn: bot?.msisdn,
        msisdn_title: bot?.msisdn_title,
        email: bot?.email,
        email_title: bot?.email_title,
        website: bot?.website,
        website_title: bot?.website_title,
        rodo: bot?.privacy_policy,
        terms: bot?.term_and_condition,
    });
  });

  const onFinish = (values) => {
    console.log('Received values of form: ', values.Upload[0]);
  };

  return(
      <Form
      layout={"vertical"}
      form={form}
      onFinish={onFinish}

      >

      <Form.Item
          name="bot_name"
          label="Nazwa bota"
          help="Nazwa bota, maksymalnie 40 znaków"
      >
        <Input
          placeholder="Nazwa bota"
          id="bot_name_input"
          />
      </Form.Item>

      <Form.Item
          name="description"
          label="Opis bota"
          help="Maksymalnie 100 znaków"
      >
        <Input
          placeholder="Opis bota"
          id="bot_description_input"
          />
      </Form.Item>

      <Form.Item
          name="bot_color"
          label="Kolor przewodni"
          help="Maksymalnie 100 znaków"
      >
        <ColorPicker />

      </Form.Item>

      <Form.Item
        name="msisdn"
        label="Numer telefonu"
        help="Wymagany format +48XXXXXXXXX"
      >
        <Input
        placeholder="Numer telefonu"
        id="bot_msisdn_input"
        />
      </Form.Item>

      <Form.Item
      name="msisdn_title"
      label="Opis numeru telefonu"
      help="Maksymalnie 25 znaków"
    >
      <Input
      placeholder="opis"
      id="bot_msisdn_title_input"
      />
    </Form.Item>

      <Form.Item
      name="email"
      label="e-mail"
      help="Maksymalnie 100 znaków"
    >
      <Input
      placeholder="e-mail"
      id="bot_email_input"
      />
    </Form.Item>

    <Form.Item
    name="email_title"
    label="Opis adresu email"
    help="Maksymalnie 25 znaków"
    >
    <Input
    placeholder="Opis"
    id="bot_email_title_input"
    />
    </Form.Item>

    <Form.Item
    name="rodo"
    label="Polityka prywatności"
    help="Maksymalnie 25 znaków"
    >
    <Input
    placeholder="url do polityki prywatności"
    id="bot_rodo_input"
    />
    </Form.Item>


    <Form.Item
    name="terms"
    label="Regulamin"
    help="Maksymalnie 25 znaków"
    >
    <Input
    placeholder="url do regulaminu"
    id="bot_terms_input"
    />
    </Form.Item>

    <Form.Item
        name="logo"
        label="Logo"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        >
      <Upload
          listType="picture-circle"
            accept="image/png, image/jpeg"
            maxCount={1}
      >
        <div>
          <PlusOutlined />
          <div
            style={{
              marginTop: 8,
            }}
          >
            Upload
          </div>
        </div>
      </Upload>
    </Form.Item>


    <Form.Item name="heroImage" label="Baner" valuePropName="fileList" getValueFromEvent={normFile}>
      <Upload listType="picture-card" accept="image/png, image/jpeg" maxCount={1}>
        <div>
          <PlusOutlined />
          <div
            style={{
              marginTop: 8,
            }}
          >
            Upload
          </div>
        </div>
      </Upload>
    </Form.Item>


      <Form.Item>
        <Button type="primary" htmlType="submit">
          Register
        </Button>
      </Form.Item>
    </Form>

  );}



export default BotEditForm;