import React, {Component} from "react";
import { Table } from "reactstrap";
import { Button, Tooltip } from 'antd';
import { BarChartOutlined } from '@ant-design/icons';
import SendRCSTemplateModal from "./SendRCSTemplateModal";


class RCSTemplateStatisticsList extends Component {


    render() {
        const rcstemplates = this.props.statistics;

        return (
            <Table dark>
                <thead>
                <tr>
                    <th>Utworzono</th>
                    <th>Plik</th>
                </tr>
                </thead>

                <tbody>
                {!rcstemplates || rcstemplates.length <= 0 || !Array.isArray(rcstemplates) ? (
                <tr>
                    <td colSpan="6" align="center">
                        <b> Nie dodano jeszcze żadnego szablonu. </b>
                    </td>
                </tr>
                ) : (
                    rcstemplates.map(rcstemplate => (
                        <tr key={rcstemplate.id}>
                            <td>{rcstemplate.created_at}</td>
                            <td><a href={`${process.env.REACT_APP_API_URL}/rcs_api/downloadreport/${rcstemplate.id}/`} download>{rcstemplate.report?.replace(/^.*[\\\/]/, '')}</a></td>
                        </tr>
                    ))
                )}
                </tbody>
            </Table>
        )
    }
}

export  default RCSTemplateStatisticsList;