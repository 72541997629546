import React, {Component} from "react";
import { Table } from "reactstrap";
import { Tooltip } from 'antd';
import SendRCSTemplateModal from "./SendRCSTemplateModal";

import { LikeOutlined, MessageOutlined, StarOutlined } from '@ant-design/icons';
import { Card, Col, Row, Avatar, List, Space } from 'antd';


const { Meta } = Card;

class RCSTemplateList extends Component {

    createReport = id => {
        const formData = new FormData();
		formData.append('id', id);
        const authTokens = localStorage.getItem("authTokens");
        const requestOptions = {
            method: 'POST',
            mode: 'cors',
            body: formData,
            headers: {Authorization: 'Bearer ' + JSON.parse(authTokens).access}
        };
        fetch(`${process.env.REACT_APP_API_URL}/rcs_api/rcstemplate/generate_report/`, requestOptions)
        alert('Raport zostanie wkrótce wygenerowany')
    };

    IconText =  ({ icon, text }: { icon: React.FC; text: string }) => (
      <Space>
        {React.createElement(icon)}
        {text}
      </Space>
    );

    render() {
        const rcstemplates = this.props.rcstemplates;

        const IconText =  ({ icon, text }: { icon: React.FC; text: string }) => (
          <Space>
            {React.createElement(icon)}
            {text}
          </Space>
        );

        return (
            <div><Table dark>
                <thead>
                <tr>
                    <th>Nazwa</th>
                    <th>Wysłano</th>
                    <th>Dostarczono</th>
                    <th>Wyświetlono</th>
                    <th>Interakcje</th>
                    <th></th>
                    <th></th>
                </tr>
                </thead>

                <tbody>
                {!rcstemplates || rcstemplates.length <= 0 || !Array.isArray(rcstemplates) ? (
                <tr>
                    <td colSpan="6" align="center">
                        <b> Nie dodano jeszcze żadnego szablonu. </b>
                    </td>
                </tr>
                ) : (
                    rcstemplates.map(rcstemplate => (
                        <tr key={rcstemplate.id}>
                            <td>{rcstemplate.name}</td>
                            <td>{rcstemplate.send}</td>
                            <td>{rcstemplate.delivered}</td>
                            <td>{rcstemplate.displayed}</td>
                            <td>{rcstemplate.interaction}</td>
                            <td>
                                <SendRCSTemplateModal
                                    create={false}
                                    rcstemplate={rcstemplate}
                                    resetState={this.props.resetState}
                                />
                                <Tooltip placement="top" title={'Wygeneruj raport'}>
                                {/*<Button*/}
                                {/*    type="primary"*/}
                                {/*    icon={<BarChartOutlined />}*/}
                                {/*    onClick={() => this.createReport(rcstemplate.id)}*/}
                                {/*    size="sm"*/}
                                {/*/>*/}
                                </Tooltip>
                            </td>
                        </tr>
                    ))
                )}
                </tbody>
            </Table>
            {/*<Card*/}
            {/*    hoverable*/}
            {/*    style={{ width: 240 }}*/}
            {/*    cover={<img alt="example" src="http://192.168.56.108:8000/media/marcin/2023/choinka_TUsb6Bj.png" />}*/}
            {/*  >*/}
            {/*    <Meta title="Europe Street beat" description="www.instagram.com" />*/}
            {/*</Card>*/}


              {/*<List*/}
              {/*  itemLayout="vertical"*/}
              {/*  size="large"*/}
              {/*  pagination={{*/}
              {/*    onChange: (page) => {*/}
              {/*      console.log(page);*/}
              {/*    },*/}
              {/*    pageSize: 3,*/}
              {/*  }}*/}
              {/*  dataSource={rcstemplates}*/}
              {/*  footer={*/}
              {/*    <div>*/}
              {/*      <b>ant design</b> footer part*/}
              {/*    </div>*/}
              {/*  }*/}
              {/*  renderItem={(item) => (*/}
              {/*    <List.Item*/}
              {/*      key={item.title}*/}
              {/*      actions={[*/}
              {/*        <IconText icon={StarOutlined} text={item.send} key="list-vertical-star-o" />,*/}
              {/*        <IconText icon={LikeOutlined} text={item.delivered} key="list-vertical-like-o" />,*/}
              {/*        <IconText icon={MessageOutlined} text={item.displayed} key="list-vertical-message" />,*/}
              {/*      ]}*/}
              {/*      extra={*/}
              {/*        <img*/}
              {/*          height='200em'*/}
              {/*          alt="logo"*/}
              {/*          src="http://192.168.56.108:8000/media/marcin/2023/choinka_TUsb6Bj.png"*/}
              {/*        />*/}
              {/*      }*/}
              {/*    >*/}
              {/*      <List.Item.Meta*/}
              {/*        avatar={<Avatar src={item.image} />}*/}
              {/*        title={<a href={item.href}>{item.name}</a>}*/}
              {/*        description={item.id}*/}
              {/*      />*/}
              {/*      {item.content}*/}
              {/*    </List.Item>)}*/}
              {/*/>*/}



            </div>
        )
    }
}

export  default RCSTemplateList;