import React, {Component, Fragment} from "react";
import {Modal, ModalHeader, ModalBody} from "reactstrap";
import { Button, Tooltip } from 'antd';
import { BarChartOutlined } from '@ant-design/icons';
import SendRCSTemplateForm from "./SendRCSTemplateForm";

class SendRCSTemplateModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modal: false
        };
    }

    toggle = () => {
        this.setState(previous => ({
            modal: !previous.modal
        }));
    };

    render() {
            const create = true;
            var title = "Wyślij wiadomość testową";
            var button = <Button onClick={this.toggle}>Wyślij</Button>;

            return (
                <Fragment>
                    <Tooltip placement="top" title={'Wyślij wiadomość testową'}>
                    {button}
                    </Tooltip>
                    <Modal isOpen={this.state.modal} toggle={this.toggle}>
                        <ModalHeader toggle={this.toggle}>{title}</ModalHeader>
                        <ModalBody>
                            <SendRCSTemplateForm
                                resetState={this.props.resetState}
                                toggle={this.toggle}
                                rcstemplate={this.props.rcstemplate}
                            />
                        </ModalBody>
                    </Modal>
                </Fragment>
            );
    }
}


export default SendRCSTemplateModal;