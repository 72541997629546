import React, { useState, useEffect } from "react";
import {Col, Container, Row} from "reactstrap";

import useAxios from "../utils/useAxios";
import HistoryList from "../components/HistoryList";
import Navbar from "../components/Navbar";

const HisotyrPage = () => {
    const [sendRequests, setSendRequests] = useState([]);
    const api = useAxios();

    useEffect(() => {
        resetState();
    }, [])

    const getSendRequests = async () => {
        try {
            const response = await api.get("/sendrequests/");
            setSendRequests(response.data);
        } catch {
            console.log("Something went wrong");
            setSendRequests([]);
        }
    }

    const resetState = () => {
        getSendRequests();
    };

    return (
        <div>
            <Navbar />
                <Container style={{ marginTop: "20px"}}>
                    <Row style={{ marginTop: "20px"}}>
                        <Col>
                            <HistoryList
                                sendRequests={sendRequests}
                                resetState={resetState}
                            />
                        </Col>
                    </Row>
            </Container>
        </div>

    );
}

export default HisotyrPage;