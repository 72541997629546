import React, { useState, useEffect } from "react";
import {Col, Container, Row} from "reactstrap";
import RCSTemplateList from "../components/RCSTemplateList";
import useAxios from "../utils/useAxios";
import RCSTemplateStatisticsList from "../components/RCSTemplateStatisticsList";
import Navbar from "../components/Navbar";

const RcsTemplatePage = () => {
    const [rcstemplates, setRCSTemplate] = useState([]);
    const api = useAxios();

    useEffect(() => {
        resetState();
    }, [])

    const getRCSTemplates = async () => {
        try {
            const response = await api.get("/rcstemplates/");
            setRCSTemplate(response.data);
        } catch {
            console.log("Something went wrong");
            setRCSTemplate([]);
        }
    }

    const resetState = () => {
        getRCSTemplates();
    };

    return (
        <div>
            <Navbar/>
            <Container style={{ marginTop: "20px"}}>
            <Row style={{ marginTop: "20px"}}>
                <Col>
                    <RCSTemplateList
                        rcstemplates={rcstemplates}
                        resetState={resetState}
                    />
                </Col>
            </Row>
        </Container>
        </div>

    );
}

export default RcsTemplatePage;
