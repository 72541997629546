
import React from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";


class NewRCSTemplateForm extends React.Component {

    constructor(props) {
    super(props);
    this.state = {
        id: 0,
        name: "",
        template: ""
    };
    }

    componentDidMount() {
        if (this.props.rcstemplate) {
            const {id, name, template} = this.props.rcstemplate;
            this.setState({id: id, name: name, template: template});
        }
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value});
    }

    createRCSTemplate = e => {
        e.preventDefault();
        const formData = new FormData();
		formData.append('template', e.target.template.files[0]);
        formData.append('name', this.state.name);
        const authTokens = localStorage.getItem("authTokens");
        const requestOptions = {
            method: 'POST',
            mode: 'cors',
            body: formData,
            headers: {Authorization: 'Bearer ' + JSON.parse(authTokens).access}
        };
        fetch(`${process.env.REACT_APP_API_URL}/rcs_api/rcstemplate/create`, requestOptions)
        .then((data) => {
                  this.props.resetState();
                  this.props.toggle();
        }).catch((error) => {
                  console.log('EROR:', error);
                });
    };

    editRCSTemplate = e => {
        e.preventDefault();

        const formData = new FormData();
		formData.append('template', e.target.template.files[0]);
        formData.append('id', this.state.id);
        formData.append('name', this.state.name);

        const requestOptions = {
            method: 'PUT',
            body:formData
        };

        fetch(`${process.env.REACT_APP_API_URL}/rcs_api/rcstemplates/${this.state.id}/`, requestOptions)
        .then(() => {
                  this.props.resetState();
                  this.props.toggle();
        })
    };

    defaultIfEmpty = value => {
        return value === "" ? "" : value;
    };

    render() {
        return (
                  <Form onSubmit={this.props.rcstemplate ? this.editRCSTemplate : this.createRCSTemplate}>
                      <FormGroup>
                          <Label for="name">Nazwa:</Label>
                          <Input
                            type="text"
                            name="name"
                            onChange={this.onChange}
                            value={this.defaultIfEmpty(this.state.name)}
                          />
                      </FormGroup>

                      <FormGroup>
                          <Label for="template">Szablon:</Label>
                          <Input
                            type="file"
                            name="template"
                            onChange={this.onChange}
                          />
                      </FormGroup>
                      {/*<Button>Dodaj szablon</Button>*/}
                  </Form>
        );
    }
}

export default NewRCSTemplateForm