import { useContext, useEffect } from "react";
import AuthContext from "../context/AuthContext";

function LogoutPage() {
  const { logoutUser } = useContext(AuthContext);

  useEffect(() => {
      logoutUser();
  });

  return (
    <section>
        <h1>Zostałeś wylogowany</h1>
    </section>
  );
};

export default LogoutPage;