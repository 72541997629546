
import { Table } from 'antd';

const HistoryList = (props) => {
    const data = props.sendRequests;

    const columns = [
        {
            title: 'Utworzono',
            dataIndex: 'created_at',
            key: 'created_at',
            width: '15%',
            sortDirections: 'descend',
        },
        {
            title: 'Szablon',
            dataIndex: 'template',

            width: '12%',
            key: 'template',
        },

        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '10%',
        },
        {
            title: 'Liczba wiadomości',
            dataIndex: 'nr_of_messages'
        },
        {
            title: 'Wysłano',
            dataIndex: 'sent',
            key: 'sent'
        },
        {
            title: 'Dostarczono',
            dataIndex: 'delivered',
            key: 'delivered'
        },
        {
            title: 'Wyświetlono',
            dataIndex: 'displayed',
            key: 'displayed'
        },
        {
            title: 'Iterakcje',
            dataIndex: 'interaction',
            key: 'interaction'
        }
    ];
    return <Table columns={columns} dataSource={data} />;
};

export default HistoryList;