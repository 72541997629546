import React, {Component, Fragment} from "react";
import { Button, Modal, ModalHeader, ModalBody} from "reactstrap";
import NewRCSTemplateForm from "./NewRCSTemplateForm";

class NewRCSTemplateModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modal: false
        };
    }

    toggle = () => {
        this.setState(previous => ({
            modal: !previous.modal
        }));
    };

    render() {
        const create = this.props.create;
        var title = "Editing template";
        var button = <Button onClick={this.toggle}>Edytuj</Button>;
        if (create) {
            title = "Dodaj nowy szablon";

            button = (
                <Button
                    color="primary"
                    className="float-right"
                    onClick={this.toggle}
                    style={{ minWidth: "200px"}}
                >
                    Dodaj szablon
                </Button>
            );
        }
        return (
            <Fragment>
                {button}
                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>{title}</ModalHeader>

                    <ModalBody>
                        <NewRCSTemplateForm
                            resetState={this.props.resetState}
                            toggle={this.toggle}
                            rcstemplate={this.props.rcstemplate}
                        />
                    </ModalBody>
                </Modal>
            </Fragment>
        );
    }
}

export default NewRCSTemplateModal;