import React, {useState, useContext} from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";

import AuthContext from "../context/AuthContext";

const NewRCSUploadForm = (props) => {
    const [file, setFile] = useState("")
    const { authTokens} = useContext(AuthContext);

    const onChange = e => {
        setFile(e.target?.upload?.files[0])
    }

    const uploadFile = (e) => {
        e.preventDefault();
        const formData = new FormData();
		formData.append('upload', e.target.upload.files[0]);

        const requestOptions = {
            method: 'POST',
            mode: 'cors',
            body: formData,
            headers: {
                Authorization: 'Bearer ' + authTokens.access
            }
        };
        fetch(`${process.env.REACT_APP_API_URL}/rcs_api/upload/create`, requestOptions)
        .then((data) => {
                  props.resetState();
                  alert("Plik został załadowany i za chwilę rozpocznie się jego weryfikacja");
        }).catch((error) => {
                  console.log('EROR:', error);
                });
    };

    return (
              <Form onSubmit={uploadFile}>
                  <FormGroup>
                      <Label for="file">Plik:</Label>
                      <Input
                        type="file"
                        name="upload"
                        onChange={onChange}
                        accept=".csv"
                      />
                  </FormGroup>
                  <Button>Załaduj</Button>
              </Form>
    );
}

export default NewRCSUploadForm