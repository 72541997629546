import { EditOutlined, GlobalOutlined, PhoneOutlined , MailOutlined, ReadOutlined } from '@ant-design/icons';
import {Avatar, Card, List, Image} from 'antd';
import {Link} from "react-router-dom";
const { Meta } = Card;

const getBotInfo = (bot) => {
    return([
      {
        item_title: bot?.msisdn_title,
        item_value: bot?.msisdn,
        item_avatar: <PhoneOutlined/>,
      },
      {
        item_title: bot?.website_title,
        item_value: bot?.website,
        item_url: bot?.website,
        item_avatar: <GlobalOutlined/>,
      },
      {
        item_title: bot?.email_title,
        item_value: bot?.email,
        item_avatar: <MailOutlined/>,
      },
      {
        item_value: bot?.privacy_policy,
        item_url: bot?.privacy_policy,
        item_title: "Polityka prywatnosci",
        item_avatar: <ReadOutlined />
      },
      {
        item_value: bot?.term_and_condition,
        item_url: bot?.term_and_condition,
        item_title: "Warunki usługi",
        item_avatar: <ReadOutlined />
      },
    ]);
}

const BotCard = (props) => (
  <Card
    style={{
      width: 380,
      border: "1px solid lightgray",
      backgroundColor: "#f8f9fa"
    }}

    cover={
      <div>
          <Image
            width={380}
            alt="heroImage"
            preview={false}
            src={props.bot?.hero_image}
            style={{
                borderStyle: 'solid',
                borderWidth: 2,
                borderColor: "lightgray",
            }}
          />
          <Image
            width={80}
            alt="heroImage"
            src={props.bot?.logo}
            style={{
                width: 80,
                height: 80,
                verticalAlign: "middle",
                borderRadius: 40,
                borderStyle: 'solid',
                borderWidth: 2,
                borderColor: "lightgray",
                marginTop: -60,
                marginLeft: 150
            }}
          />
      </div>
    }
    actions={[
      <EditOutlined key="edit" />,
    ]}
  >
    <Meta
      // avatar={<Avatar src={props.bot?.logo}/>}
      title={props.bot?.bot_name}
      description={props.bot?.description}
      align="center"
    />
      <a>{"\n"}</a>
        <List
            itemLayout="horizontal"
            dataSource={getBotInfo(props.bot)}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  avatar={item.item_avatar}
                  title=<a href={item?.item_url}>{item.item_title}</a>
                  description={item.item_value}
                />
              </List.Item>
            )}
        />
  </Card>
);
export default BotCard;