import React, {Component, Fragment} from "react";
import {Modal, ModalHeader, Button, ModalBody} from "reactstrap";
import UploadSendForm from "./UploadSendForm";

class UploadSendModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modal: false
        };
    }

    toggle = () => {
        this.setState(previous => ({
            modal: !previous.modal
        }));
    };

    render() {
            var title = "Send Message";
            var button = <Button onClick={this.toggle}>Wyślij</Button>;

            return (
                <Fragment>
                    {button}
                    <Modal isOpen={this.state.modal} toggle={this.toggle}>
                        <ModalHeader toggle={this.toggle}>{title}</ModalHeader>

                        <ModalBody>
                            <UploadSendForm
                                resetState={this.props.resetState}
                                templates={this.props.templates}
                                toggle={this.toggle}
                                upload={this.props.upload}
                            />
                        </ModalBody>
                    </Modal>
                </Fragment>
            );
    }
}


export default UploadSendModal;