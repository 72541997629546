
import React, {useContext, useEffect, useState} from "react";
import {Button, Form, FormGroup, Input, Label} from "reactstrap";
import {Select} from "antd";

import AuthContext from "../context/AuthContext";
import axios from "axios";


function UploadSendForm(props) {
    const [id, setId] = useState(0);
    const [template_id, setTemplate] = useState("");
    const [templates, setTemplates] = useState([]);
    const { authTokens } = useContext(AuthContext);
    const [error, setError] = useState("");

    // on load setstate
    useEffect(() => {
        if (props.upload) {
            setId(props.upload.id);
        }
        var listOfObjects = [];
        var a = props.templates;
        a.forEach(function(entry) {
        var singleObj = {};
        singleObj['value'] = entry.id;
        singleObj['label'] = entry.name;
        listOfObjects.push(singleObj);
        });
        setTemplates(listOfObjects);
    }, []);

    const sendRCSTemplate = async (e) =>  {
        e.preventDefault();
        const formData = new FormData();
        formData.append('upload_id', id.toString());
        formData.append('template_id', template_id.toString());


        const response = await fetch(`${process.env.REACT_APP_API_URL}/rcs_api/upload/send`, {
            method: "POST",
            headers: {
                Authorization: 'Bearer ' + authTokens.access
            },
            body: formData,
        });
        console.log("RESPONSE ')" + response);
        if (response.ok) {
            props.toggle();
            alert("Rozpoczęto proces wysyłki wiadomości, aby sprawdzić postęp przejdź do zakładki: Historia Wysyłek");
        } else {
            setError('Nie udało się wysłać wiadomości. Staus błędu: ', response.text());
        }
        const data = await response.json();
    };


    const onTemplateChange= (event) => {
        const template_id = event;
        setTemplate(template_id);
        console.log(templates);
    }

    return (
        <Form onSubmit={sendRCSTemplate}>
            <FormGroup>
                <p id ='errors' style={{color: "red"}}>{error}</p>
                <Label for="template_id">Szablon:</Label>
                <Select
                  type="text"
                  style={{ width: 300 }}
                  onChange={onTemplateChange}
                    showSearch
                    placeholder="Select a template"
                    optionFilterProp="children"

                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }

                  options={templates}
                />


            </FormGroup>
            <Button>Wyślij</Button>
        </Form>
    );
}

export default UploadSendForm;