import React from "react";
import { Routes, Route, BrowserRouter} from "react-router-dom";
import RcsTemplatePage from "./views/RcsTemplatePage";
import { AuthProvider } from "./context/AuthContext";
import HomePage from "./components/Home";
import Login from "./views/loginPage";
import LogoutPage from "./views/logoutPage";
import SessionTimeout from "./utils/SessionTimeout";


import UploadsPage from "./views/UploadsPage";
import {ProtectedRoute} from "./context/RequireAuth";
import MessagesPage from "./views/MessagesPage";
import HistoryPage from "./views/HistoryPage";
import ReportsPage from "./views/ReportsPage";
import BotPage from "./views/BotPage";
import DemoPage from "./views/DemoPage";



function App(){

    return (

        <BrowserRouter>
            <div className="flex flex-col min-h-screen overflow-hidden">
            <AuthProvider>
                <SessionTimeout/>
                  <Routes>
                    {/*Public routes*/}
                    <Route path="/login" element={<Login />} />
                    <Route path="/logout" element={<LogoutPage />}/>
                    <Route path="/demo" element={<DemoPage />}/>
                    {/*<Route path="/register" element={<Register />}/>*/}

                    {/*Private routes*/}
                    <Route path="/" element={<ProtectedRoute><HomePage /></ProtectedRoute>}></Route>
                    <Route path="/templates" element={<ProtectedRoute><RcsTemplatePage /></ProtectedRoute>}></Route>
                    <Route path="/uploads" element={<ProtectedRoute><UploadsPage /></ProtectedRoute>}></Route>
                    <Route path="/messages" element={<ProtectedRoute><MessagesPage /></ProtectedRoute>}></Route>
                    <Route path="/history" element={<ProtectedRoute><HistoryPage /></ProtectedRoute>}></Route>
                    <Route path="/reports" element={<ProtectedRoute><ReportsPage /></ProtectedRoute>}></Route>
                    <Route path="/bot" element={<ProtectedRoute><BotPage /></ProtectedRoute>}></Route>
                  </Routes>
            </AuthProvider>
            </div>
        </BrowserRouter>
    );
}

export default App;
